<template>
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
      />
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:">
          <TicketTable
            v-if="backendPagination"
            :tablePaginatedData="tickets"
            :backendPagination="backendPagination"
            :to="to"
            :from="from"
            :total="total"
            :lastPage="lastPage"
            @firstPage="firstPage"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @lastPage="goLastPage"
            @activeBanner="
              (activeBanner = true), getTicketsClosed(), (key = !key)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
            :statuses="status"
            @pushStatusSearch="this.pushSearchStatus($event)"
            :searchStatus="this.searchStatus"
            @clearStatusFilter="
              (this.searchStatus.length = 0), this.getTicketsClosed()
            "
            :searchPriority="this.searchPriority"
            @pushPrioritySearch="this.pushSearchPriority($event)"
            @clearPriorityFilter="
              (this.searchPriority.length = 0), this.getTicketsClosed()
            "
          />
        </div>
        <!-- <div v-else class="text-center mt-12">
          <svg
            class="mx-auto h-16 w-16 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
            />
          </svg>
          <h3 class="mt-2 text-xl font-medium text-gray-900">
            No Ticket Closed
          </h3>
          <p class="mt-1 text-base text-gray-500">
            You don't have any closed tickets at the moment.
          </p>
        </div> -->
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import TicketTable from "../../components/tickets/TicketTable.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";

const priorities = [
  { id: 1, name: "basse" },
  { id: 2, name: "normale" },
  { id: 3, name: "haute" },
  { id: 4, name: "critique" },
];

export default {
  props: ["page"],
  components: {
    TicketTable,
    Loading,
    SuccessBanner,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      tickets: [],
      to: "",
      from: "",
      total: "",
      lastPage: "",
      backendPagination: false,
      currentPage: 1,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      status: [],
      searchStatus: [],
      searchPriority: [],
      priorities,
    };
  },
  methods: {
    async getTicketsClosed() {
      console.log(this.searchPriority);
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketsClosed?page=${
            this.page
          }&filter[status_id]=${this.searchStatus}&filter[priority_id]=${
            this.searchPriority
          }`
        );
        if (res) {
          this.backendPagination = true;
          this.isLoading = false;
          this.tickets = res.data.data;
          this.isLoading = false;
          this.to = res.data.to;
          this.from = res.data.from;
          this.total = res.data.total;
          this.lastPage = res.data.last_page;
          this.$emit("getTicketCount");
        }
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;

      this.$router.push(`/closed-tickets/${firstPage}`);
    },
    nextPage() {
      let nextPage = null;
      if (parseInt(this.page) < parseInt(this.lastPage)) {
        nextPage = parseInt(this.page) + 1;
      } else {
        nextPage = parseInt(this.page);
      }
      this.currentPage = nextPage;
      this.$router.push(`/closed-tickets/${nextPage}`);
    },
    previousPage() {
      let previousPage = null;
      if (this.page > 1) {
        previousPage = parseInt(this.page) - 1;
      } else {
        previousPage = parseInt(this.page);
      }
      this.currentPage = previousPage;
      this.$router.push(`/closed-tickets/${previousPage}`);
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.lastPage;
      this.$router.push(`/closed-tickets/${this.lastPage}`);
      this.$forceUpdate();
    },
    pushSearchPriority(priority) {
      console.log(priority);
      if (!this.searchPriority.includes(this.checkPriority(priority))) {
        this.searchPriority.push(this.checkPriority(priority));
      } else {
        let index = this.searchPriority.indexOf(this.checkPriority(priority));
        if (index > -1) {
          this.searchPriority.splice(index, 1);
        }
      }
      this.getTicketsClosed();
      this.$forceUpdate();
    },
    checkPriority(id) {
      for (let index = 0; index < this.priorities.length; index++) {
        if (this.priorities[index].id == id) {
          return this.priorities[index].name;
        }
      }
    },
    pushSearchStatus(status) {
      console.log(status);
      if (!this.searchStatus.includes(this.checkStatus(status))) {
        this.searchStatus.push(this.checkStatus(status));
      } else {
        let index = this.searchStatus.indexOf(this.checkStatus(status));
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.getTicketsClosed();
      this.$forceUpdate();
    },
    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
      console.log(res.data);
    },
    checkStatus(name) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].name == name) {
            return this.status[index].id;
          }
        }
      }
    },
    checkPriority(name) {
      for (let index = 0; index < this.priorities.length; index++) {
        if (this.priorities[index].name == name) {
          return this.priorities[index].id;
        }
      }
    },
  },
  mounted() {
    this.getTicketsClosed();
    this.getStatus();
  },
  watch: {
    searchStatus: function() {},
  },
};
</script>

<style></style>
